import { ColumnType } from "../constants/columnType";
import { LabelType } from "../constants/labelType";
import { ThumbnailType } from "../constants/thumbnailType";
import { HtmlGenerationParams } from "../types/htmlGenerationParams";

import Papa from "papaparse";

type TsvRow = {
  id: number;
  title: string;
  display_author_name: string;
  icon_url: string;
  square_image_url: string | null;
  thumbnail_url: string;
  share_url: string;
  labelType: string;
  labelText: string;
  columnType: string;
  thumbnailType: string;
  isRanking: string;
};

export default class TsvConverter {
  static toListItem(tsv: string): HtmlGenerationParams {
    const rows = Papa.parse<TsvRow>(tsv, { header: true }).data;
    const columnType = rows[0].columnType as ColumnType;
    const thumbnailType = rows[0].thumbnailType as ThumbnailType;
    const isRanking = rows[0].isRanking === "true";
    const listItems = rows.map((row) => {
      return {
        content: {
          id: row.id,
          meta: {
            title: row.title,
            display_author_name: row.display_author_name,
            icon_url: row.icon_url,
            square_image_url: row.square_image_url,
            thumbnail_url: row.thumbnail_url,
            share_url: row.share_url,
          },
        },
        labelType: row.labelType as LabelType,
        labelText: row.labelText,
      };
    });
    return {
      listItems,
      columnType,
      thumbnailType,
      isRanking,
    };
  }

  static fromListItem(params: HtmlGenerationParams): string {
    const rows: TsvRow[] = params.listItems.map((item) => {
      return {
        id: item.content.id,
        title: item.content.meta.title,
        display_author_name: item.content.meta.display_author_name,
        icon_url: item.content.meta.icon_url,
        square_image_url: item.content.meta.square_image_url,
        thumbnail_url: item.content.meta.thumbnail_url,
        share_url: item.content.meta.share_url,
        labelType: item.labelType,
        labelText: item.labelText,
        columnType: params.columnType,
        thumbnailType: params.thumbnailType,
        isRanking: params.isRanking ? "true" : "false",
      };
    });
    return Papa.unparse(rows, { header: true, delimiter: "\t" });
  }
}
