import { Grid } from "@material-ui/core";
import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { AppContext } from "../App";
import TsvConverter from "../util/TsvConverter";
import { HtmlGenerationParams } from "../types/htmlGenerationParams";

const ContentsFetchForm = () => {
  const { setListItems, setColumnType, setThumbnailType } =
    useContext(AppContext);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const text = reader.result as string;
          const params: HtmlGenerationParams = TsvConverter.toListItem(text);
          setListItems(params.listItems);
          setColumnType(params.columnType);
          setThumbnailType(params.thumbnailType);
        } catch (e) {
          setErrorMessage("ファイルの読み込みに失敗しました");
        }
      };
      reader.readAsText(file);
    },
    [setListItems, setColumnType, setThumbnailType]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <input type="file" onChange={handleFileChange} />
        {errorMessage !== "" && <span>{errorMessage}</span>}
      </Grid>
    </Grid>
  );
};

export default ContentsFetchForm;
