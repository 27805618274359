import React, { useCallback, useState, useContext } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { ListItem } from "../types/listItem";
import { Content } from "../types/content";
import { LabelType } from "../constants/labelType";
import ApiClient from "../util/ApiClient";
import { AppContext } from "../App";

const ContentsFetchForm = () => {
  const { setListItems } = useContext(AppContext);
  const [contentIdsStr, setContentIdsStr] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onClick = useCallback(() => {
    const contentIds: number[] = contentIdsStr
      .split("\n")
      .map((v) => parseInt(v))
      .filter((v) => v);

    setIsLoading(true);
    Promise.all(contentIds.map((id) => ApiClient.fetchContent(id)))
      .then((contents: Content[]) => {
        const items: ListItem[] = contents.map((v) => ({
          content: v,
          labelType: LabelType.None,
          labelText: "",
        }));
        setListItems(items);
        setErrorMessage("");
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          setErrorMessage("存在しない作品IDが含まれています");
        } else {
          setErrorMessage("取得中にエラーが発生しました");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [contentIdsStr, setListItems, setErrorMessage]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="作品ID（改行区切り）"
          multiline
          minRows={6}
          variant="outlined"
          value={contentIdsStr}
          onChange={(event) => setContentIdsStr(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={onClick}
        >
          作品情報取得
        </Button>
        {errorMessage !== "" && <span>{errorMessage}</span>}
      </Grid>
    </Grid>
  );
};

export default ContentsFetchForm;
