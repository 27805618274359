import React, { useState } from "react";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { ListItem } from "./types/listItem";
import ContentsFetchForm from "./components/ContentsFetchForm";
import ContentsEditForm from "./components/ContentsEditForm";
import HtmlGenerateForm from "./components/HtmlGenerateForm";
import FormWrapper from "./components/FormWrapper";
import TsvPasteForm from "./components/TsvSelectForm";
import { ColumnType } from "./constants/columnType";
import { ThumbnailType } from "./constants/thumbnailType";

export const AppContext: React.Context<{
  listItems: ListItem[];
  setListItems: React.Dispatch<React.SetStateAction<ListItem[]>>;
  columnType: ColumnType;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType>>;
  thumbnailType: ThumbnailType;
  setThumbnailType: React.Dispatch<React.SetStateAction<ThumbnailType>>;
}> = React.createContext<any>(null);

const App = () => {
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const [columnType, setColumnType] = useState<ColumnType>(ColumnType.Single);
  const [thumbnailType, setThumbnailType] = useState<ThumbnailType>(
    ThumbnailType.Square
  );

  return (
    <AppContext.Provider
      value={{
        listItems,
        setListItems,
        columnType,
        setColumnType,
        thumbnailType,
        setThumbnailType,
      }}
    >
      <Container maxWidth="lg">
        <Box m={2}>
          <Typography variant="h4">
            ニコニコ漫画お知らせブログ作品一覧HTML生成ツール
          </Typography>
          <Typography variant="overline" gutterBottom>
            ※予期せぬ仕様変更で動かなくなる可能性があります。即座にエンジニアにご相談ください。
          </Typography>
        </Box>

        <FormWrapper
          title="STEP1 作品情報読み込み"
          description={
            <span>
              1から作成する場合は「作品IDから取得」を利用してください。
              <br />
              このツールで保存したTSVを読み込む場合は「TSVから読み込む」を利用してください。
            </span>
          }
          form={
            <Grid container spacing={2}>
              <Box display="flex">
                <Box m={1}>
                  <FormWrapper
                    title="作品IDから取得"
                    description="作品IDを改行区切りで入力し、「作品情報取得」をクリックしてください。取得完了すると、STEP2に取得結果が表示されます。"
                    form={<ContentsFetchForm />}
                  />
                </Box>
                <Box m={1}>
                  <FormWrapper
                    title="TSVから読み込む"
                    description="ファイルを選択してください。読み込みが完了すると、STEP2とSTEP3（作品表示・サムネイル種別・ランキング数字）に反映されます。"
                    form={<TsvPasteForm />}
                  />
                </Box>
              </Box>
            </Grid>
          }
        />

        <FormWrapper
          title="STEP2 作品一覧編集"
          description="ラベルを付与する場合、ラベル種別（色）とラベルテキストを入力してください。入力が完了したら（あるいはラベルが必要ない場合は）「STEP3 HTML出力」へ進んでください。"
          form={<ContentsEditForm />}
        />

        <FormWrapper
          title="STEP3 HTML出力"
          description={
            <span>
              サムネイル種別と、ランキング数字を表示するかどうかを選択し、「HTML出力」をクリックしてください。出力結果が表示されたら、ブログ記事へコピペしてください。
              <br />
              再編集する可能性がある場合、「TSVで保存」をクリックし、TSVファイルを保存してください。
            </span>
          }
          form={<HtmlGenerateForm />}
        />
      </Container>
    </AppContext.Provider>
  );
};

export default App;
