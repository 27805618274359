import React, { useCallback, useContext } from "react";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { LabelType } from "../constants/labelType";
import { AppContext } from "../App";

const ContentsEditForm = () => {
  const { listItems, setListItems } = useContext(AppContext);

  const handleLabelTypeChange = useCallback(
    (id: number, labelType: LabelType) => {
      const tmpcontentListItems = listItems.slice();
      const matched = tmpcontentListItems.find((c) => c.content.id === id);
      if (matched != null) {
        matched.labelType = labelType;
        setListItems(tmpcontentListItems);
      }
    },
    [listItems, setListItems]
  );

  const handleLabelTextChange = useCallback(
    (id: number, text: string) => {
      const tmpcontentListItems = listItems.slice();
      const matched = tmpcontentListItems.find((c) => c.content.id === id);
      if (matched != null) {
        matched.labelText = text;
        setListItems(tmpcontentListItems);
      }
    },
    [listItems, setListItems]
  );
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell>ラベル種別</TableCell>
            <TableCell>ラベルテキスト</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listItems.map((item) => (
            <TableRow key={item.content.id}>
              <TableCell>{item.content.id}</TableCell>
              <TableCell>{item.content.meta.title}</TableCell>
              <TableCell>
                <FormControl>
                  <Select
                    value={item.labelType}
                    onChange={(event) =>
                      handleLabelTypeChange(
                        item.content.id,
                        event.target.value as LabelType
                      )
                    }
                  >
                    {Object.entries(LabelType).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  value={item.labelText}
                  onChange={(event) =>
                    handleLabelTextChange(item.content.id, event.target.value)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContentsEditForm;
