import { ListItem } from "../types/listItem";
import { ColumnType } from "../constants/columnType";
import { ThumbnailType } from "../constants/thumbnailType";
import { LabelType } from "../constants/labelType";

import * as cheerio from "cheerio";
import { Content } from "../types/content";
import { HtmlGenerationParams } from "../types/htmlGenerationParams";

export default class BlogHtmlGenerator {
  private static generateThumbnail(thumbnailUrl: string): string {
    return `
<!-- wp:image {"sizeSlug":"large","linkDestination":"none"} -->
<figure class="wp-block-image size-large"><img src="${thumbnailUrl}" alt="" /></figure>
<!-- /wp:image -->
`;
  }

  private static generateTitle(columnType: ColumnType, title: string): string {
    const additionalClass =
      columnType === ColumnType.Multiple ? " truncate-multiline" : "";
    return `
<!-- wp:heading {"level":5,"style":{"elements":{"link":{"color":{"text":"var:preset|color|custom-link"}}}},"textColor":"custom-link"} -->
<h5 class="wp-block-heading has-custom-link-color has-text-color has-link-color${additionalClass}">${title}</h5>
<!-- /wp:heading -->
`;
  }

  private static generateAuthor(author: string): string {
    return `
<!-- wp:paragraph {"fontSize":"small"} -->
<p class="1has-small-font-size">${author}</p>
<!-- /wp:paragraph -->
`;
  }

  private static generateLabelsWrapper(): string {
    return `
<!-- wp:buttons -->
<div class="nm-comic__details__labels wp-block-buttons">
</div>
<!-- /wp:buttons -->
`;
  }

  private static generateRankLabel(rank: number): string {
    return `
<!-- wp:button {"backgroundColor":"secondary","textColor":"white","style":{"elements":{"link":{"color":{"text":"var:preset|color|white"}}},"spacing":{"padding":{"left":"8px","right":"8px","top":"2px","bottom":"2px"}},"border":{"radius":"0px"}},"fontSize":"small"} -->
<div class="wp-block-button has-custom-font-size has-small-font-size">
  <a class="wp-block-button__link has-white-color has-secondary-background-color has-text-color has-background has-link-color wp-element-button" style="border-radius:0px;padding-top:2px;padding-right:8px;padding-bottom:2px;padding-left:8px">${rank}位</a>
</div>
<!-- /wp:button -->
`;
  }

  private static generateLabel(type: LabelType, text: string): string {
    const textColor = type === LabelType.Green ? "white" : "black";
    return `
<!-- wp:button {"backgroundColor":"custom-label-${type}","textColor":"${textColor}","style":{"elements":{"link":{"color":{"text":"var:preset|color|${textColor}"}}},"spacing":{"padding":{"left":"8px","right":"8px","top":"2px","bottom":"2px"}},"border":{"radius":"50px"}},"fontSize":"small"} -->
<div class="wp-block-button has-custom-font-size has-small-font-size">
  <a class="wp-block-button__link has-${textColor}-color has-custom-label-${type}-background-color has-text-color has-background has-link-color wp-element-button" style="border-radius:50px;padding-top:2px;padding-right:8px;padding-bottom:2px;padding-left:8px">${text}</a>
</div>
<!-- /wp:button -->
`;
  }

  private static generateSingleColumnTypeLayout(
    thumbnailClassName: string,
    detailsClassName: string
  ): string {
    return `
<!-- wp:columns {"isStackedOnMobile":false,"style":{"spacing":{"blockGap":{"left":"var:preset|spacing|40"}}}} -->
<div class="wp-block-columns is-not-stacked-on-mobile">
  <!-- wp:column {"width":"100px"} -->
  <div class="${thumbnailClassName} wp-block-column" style="flex-basis:100px"></div>
  <!-- /wp:column -->

  <!-- wp:column {"width":"","style":{"spacing":{"blockGap":"0"}}} -->
  <div class="${detailsClassName} wp-block-column"></div>
  <!-- /wp:column -->
</div>
<!-- /wp:columns -->
`;
  }

  private static generateColumn(): string {
    return `
  <!-- wp:column -->
  <div class="wp-block-column"></div>
  <!-- /wp:column -->
`;
  }

  private static generateColumns(isStackedOnMobile: boolean): string {
    let [option, additionalClass] = ["", ""];
    if (isStackedOnMobile) {
      option = ',"isStackedOnMobile":false';
      additionalClass = " is-not-stacked-on-mobile";
    }
    return `
<!-- wp:columns {"style":{"spacing":{"blockGap":{"left":"var:preset|spacing|40"}}}${option}} -->
<div class="wp-block-columns${additionalClass}"></div>
<!-- /wp:columns -->
`;
  }

  private static generateContentWrapper(
    columnType: ColumnType,
    className: string,
    contentUrl: string
  ): string {
    const style =
      columnType === ColumnType.Multiple
        ? ',"style":{"spacing":{"blockGap":"var:preset|spacing|30"}}'
        : "";
    return `<!-- wp:group {"layout":{"type":"constrained"},"href":"${contentUrl}"${style}} -->
<div class="${className} wp-block-group ek-linked-block">
</div>
<!-- /wp:group -->`;
  }

  private static generateSingleColumnContent(
    content: Content,
    thumbnailType: ThumbnailType,
    labelType: LabelType,
    labelText: string,
    rank?: number | null
  ): string {
    const title = content.meta.title;
    const author = content.meta.display_author_name;
    const url = content.meta.share_url;

    // todo: エピソードサムネ向けの幅変更どうしようかなー…
    const thumbnailUrl =
      thumbnailType === ThumbnailType.LatestEpisode
        ? content.meta.thumbnail_url
        : content.meta.square_image_url || content.meta.icon_url;

    const wrapperClass = "nm-comic";
    const thumbnailClassName = "nm-comic___thumbnail";
    const detailsClassName = "nm-comic___details";
    const $ = cheerio.load("");
    $("body").append(
      this.generateContentWrapper(ColumnType.Single, wrapperClass, url)
    );

    $(`.${wrapperClass}`).append(
      this.generateSingleColumnTypeLayout(thumbnailClassName, detailsClassName)
    );

    $(`.${thumbnailClassName}`).append(this.generateThumbnail(thumbnailUrl));

    if (labelType !== LabelType.None || rank != null) {
      $(`.${detailsClassName}`).append(this.generateLabelsWrapper());
      if (rank != null) {
        $(".nm-comic__details__labels").append(this.generateRankLabel(rank));
      }
      if (labelType !== LabelType.None) {
        $(".nm-comic__details__labels").append(
          this.generateLabel(labelType, labelText)
        );
      }
    }

    $(`.${detailsClassName}`).append(
      this.generateTitle(ColumnType.Single, title)
    );
    $(`.${detailsClassName}`).append(this.generateAuthor(author));

    return $("body").html() ?? "";
  }

  private static generateSingleColumnContents(
    contentListItems: ListItem[],
    thumbnailType: ThumbnailType,
    isRanking: boolean
  ): string {
    return contentListItems
      .map((item, i) => {
        const rank = isRanking ? i + 1 : null;

        return this.generateSingleColumnContent(
          item.content,
          thumbnailType,
          item.labelType,
          item.labelText,
          rank
        );
      })
      .join("");
  }

  private static generateMultipleColumnContents(
    contentListItems: ListItem[]
  ): string {
    let minimumLength = contentListItems.length;
    if (contentListItems.length % 4 !== 0) {
      // 作品数が4の倍数でない場合にレイアウトが崩れるため、4の倍数を並べる要素の最小数にする
      minimumLength = Math.ceil(minimumLength / 4) * 4;
    }

    let elements = [];
    for (let i = 0; i < minimumLength; i++) {
      const item = contentListItems[i];
      const $ = cheerio.load("");
      $("body").append(this.generateColumn());

      if (item != null) {
        const wrapperClass = "nm-comic";
        $("div").append(
          this.generateContentWrapper(
            ColumnType.Multiple,
            wrapperClass,
            item.content.meta.share_url
          )
        );

        $(`.${wrapperClass}`).append(
          this.generateThumbnail(
            item.content.meta.square_image_url || item.content.meta.icon_url
          )
        );

        if (item.labelType !== LabelType.None) {
          $(`.${wrapperClass}`).append(this.generateLabelsWrapper());
          $(".nm-comic__details__labels").append(
            this.generateLabel(item.labelType, item.labelText)
          );
        }

        $(`.${wrapperClass}`).append(
          this.generateTitle(ColumnType.Multiple, item.content.meta.title)
        );
      }

      elements.push($("body").html() ?? "");
    }

    // 最小でも2列ずつの配置になるため、2要素ずつのペアに分けてブロックを構成する
    let elements2tuple = [];
    for (let i = 0; i < elements.length; i += 2) {
      const pair = elements.slice(i, i + 2);
      const $ = cheerio.load("");
      $("body").append(this.generateColumn());
      $(".wp-block-column").append(this.generateColumns(true));
      $(".wp-block-columns").append(pair.join(""));
      elements2tuple.push($("body").html() ?? "");
    }

    // PCでは4列、モバイルでは2列になるため、2要素*2の4要素を1つのブロックにし、2要素ごとに回り込むようにする
    let elements4tuple = [];
    for (let i = 0; i < elements2tuple.length; i += 2) {
      const pair = elements2tuple.slice(i, i + 2);
      const $ = cheerio.load("");
      $("body").append(this.generateColumns(false));
      $(".wp-block-columns").append(pair.join(""));
      elements4tuple.push($("body").html() ?? "");
    }

    return elements4tuple.join("\n");
  }

  static generate(params: HtmlGenerationParams): string {
    if (params.columnType === ColumnType.Single) {
      return this.generateSingleColumnContents(
        params.listItems,
        params.thumbnailType,
        params.isRanking
      );
    } else {
      return this.generateMultipleColumnContents(params.listItems);
    }
  }
}
