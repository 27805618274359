import React, { useCallback, useState, useContext } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { ColumnType } from "../constants/columnType";
import { ThumbnailType } from "../constants/thumbnailType";
import BlogHtmlGenerator from "../util/BlogHtmlGenerator";
import { AppContext } from "../App";
import TsvConverter from "../util/TsvConverter";

const HtmlGenerateForm = () => {
  const {
    listItems,
    columnType,
    setColumnType,
    thumbnailType,
    setThumbnailType,
  } = useContext(AppContext);

  const [isRanking, setIsRanking] = useState<boolean>(false);
  const [generatedHtml, setGeneratedHtml] = useState<string>("");

  const generateHtml = useCallback(() => {
    const html = BlogHtmlGenerator.generate({
      listItems,
      columnType,
      thumbnailType,
      isRanking,
    });

    setGeneratedHtml(html);
  }, [listItems, columnType, thumbnailType, isRanking, setGeneratedHtml]);

  const downloadTsv = useCallback(() => {
    const tsv = TsvConverter.fromListItem({
      listItems,
      columnType,
      thumbnailType,
      isRanking,
    });
    const blob = new Blob([tsv], { type: "text/tab-separated-values" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `blog_contents_${new Date()
      .toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\D/g, "")}.tsv`;
    a.click();
    URL.revokeObjectURL(url);
  }, [columnType, isRanking, listItems, thumbnailType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <FormControl>
          <InputLabel id="column-type-label">作品表示</InputLabel>
          <Select
            labelId="column-type-label"
            value={columnType}
            onChange={(event) => {
              setColumnType(event.target.value as ColumnType);
            }}
          >
            {Object.entries(ColumnType).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel id="thumbnail-type-label">サムネイル種別</InputLabel>
          <Select
            labelId="thumbnail-type-label"
            value={thumbnailType}
            onChange={(event) => {
              setThumbnailType(event.target.value as ThumbnailType);
            }}
          >
            {Object.entries(ThumbnailType).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Switch
              checked={isRanking}
              onChange={(event) => setIsRanking(event.target.checked)}
              color="primary"
            />
          }
          label="ランキング数字を付与する"
        />
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={generateHtml}>
          HTML出力
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={downloadTsv}>
          TSVで保存
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          minRows={15}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          value={generatedHtml}
        />
      </Grid>
    </Grid>
  );
};

export default HtmlGenerateForm;
