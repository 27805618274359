import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  title: string;
  description: React.ReactNode;
  form: React.ReactNode;
}

const FormWrapper = (props: Props) => {
  const classes = useStyles();
  const { title, description, form } = props;

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {description}
      </Typography>
      {form}
    </Paper>
  );
};

export default FormWrapper;
