import axios from "axios";
import { Content } from "../types/content";
import { Response } from "../types/response";

export default class ApiClient {
  static async fetchContent(id: number): Promise<Content> {
    const { data } = await axios.get<Response<Content>>(
      `https://api.nicomanga.jp/api/v1/app/manga/contents/${id}?ref=mangablog_html_gen`,
      {}
    );
    return data.data.result;
  }
}
